import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';

export const CyberArkCreateIntegration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 1: Create SAML Integration
    </Text>

    <Img
      priority
      height={775}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-4.png"
      width={1522}
    />

    <Paragraph>
      Log in to the CyberArk Identity Admin Portal and select "Web Apps" from
      the left-side navigation.
    </Paragraph>

    <Img
      priority
      height={652}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-5.png"
      width={1525}
    />

    <Paragraph>
      Select "Add Web Apps" to begin creating a new SAML application.
    </Paragraph>

    <Img
      height={667}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-6.png"
      width={895}
    />

    <Paragraph>Select the "Custom" tab and then click to add "SAML".</Paragraph>

    <Img
      height={452}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-7.png"
      width={433}
    />

    <Paragraph>Select "Yes" to begin setting up the SAML App.</Paragraph>

    <Img
      height={846}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-8-edited.png"
      width={1164}
    />

    <Paragraph>
      Enter a descriptive App Name (such as {appName}) and a Description, then
      click "Save".
    </Paragraph>

    <CopyInput
      label="Copy this Single Sign On URL"
      value={connection?.saml_acs_url}
    />

    <Paragraph>
      On the "Trust" tab of the SAML App, go to the "Service Provider
      Configuration Section" and select "Manual Configuration". Input the Single
      Sign On URL above as both the "SP Entity Id / Issuer / Audience" and
      "Assertion Consumer Service (ACS) URL".
    </Paragraph>

    <Img
      height={794}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-9-edited.png"
      width={1186}
    />

    <Paragraph>
      IMPORTANT: Be sure to check "Both" under "Sign Response or Assertion?"
    </Paragraph>

    <Confirm
      label="I’ve configured the SP Entity ID / Issuer / Audience and ACS URL."
      onClick={onNextStep}
    />
  </Article>
);

export const CyberArkConfigureAttributeStatements: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 2: Configure Attribute Statements
    </Text>

    <Img
      priority
      height={841}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-11.png"
      width={1190}
    />

    <Paragraph>
      Select the "SAML Response" tab and use the "Add" button to add the
      following key-value pairs. Then, click "Save".
    </Paragraph>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I’ve finished configuring the Attribute Statements
          and clicked “Save”."
      onClick={onNextStep}
    />
  </Article>
);

export const CyberArkAddUsers: React.FC<Readonly<ConnectionStepProps>> = ({
  onNextStep,
}) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 3: Add Users to SAML App
    </Text>

    <Paragraph>
      To give users permission to authenticate via this SAML app, you will need
      to assign individual users and/or groups of users to the CyberArk SAML
      app.
    </Paragraph>

    <Img
      priority
      height={840}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-12.png"
      width={1200}
    />

    <Paragraph>Click on the "Permissions" tab, and select "Add"</Paragraph>

    <Img
      height={594}
      src="/sso/cyber-ark-saml/v1/cyber-ark-saml-14.png"
      width={757}
    />

    <Paragraph>
      Search for the individual user(s) and/or group(s) that you would like to
      assign to the app, and check the box next to them. Click "Add" when you
      are finished. Once users have been successfully added, you should also
      notice the "Status" of your CyberArk SAML app change to "Deployed".
    </Paragraph>

    <Confirm
      label="I’ve users and/or groups to the SAML app."
      onClick={onNextStep}
    />
  </Article>
);

export const CyberArkUploadMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => {
  const isUseGeneratedConnectionEntityIdEnabled = useFeature(
    'useGeneratedConnectionEntityId',
  );

  return (
    <Article>
      <Text as="h1" size="xlarge" weight="medium">
        Step 4: Upload Identity Provider Metadata
      </Text>

      <Img
        priority
        height={840}
        src="/sso/cyber-ark-saml/v1/cyber-ark-saml-10.png"
        width={1181}
      />

      <Paragraph>
        You can find the necessary metadata under the "Trust" tab of the SAML
        app in the "Identity Provider Configuration" section. Enter the values
        into the corresponding fields below, and upload the Signing Certifcate
        that you download.
      </Paragraph>

      <Card>
        <Form
          disabled={
            !connection?.saml_idp_url ||
            !connection?.saml_x509_certs ||
            !connection?.saml_entity_id ||
            validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
          }
          isLoading={isLoading}
          isUpdate={
            !!(
              errors?.saml_idp_url ||
              errors?.saml_entity_id ||
              errors?.saml_x509_certs
            )
          }
          onSubmit={onNextStep}
        >
          <TextField
            autoFocus={true}
            error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
            label="1. Single Sign On URL"
            name="saml_idp_url"
            onChange={onInputChange}
            placeholder="https://aaf4346.my.idaptive.app/applogin/appKey/e921b128-0543-4f7b-9968-ab92ec522f2e/customerId/AAF4347"
            value={connection?.saml_idp_url}
          />

          {isUseGeneratedConnectionEntityIdEnabled ? (
            <FileField
              error={errors?.saml_x509_certs}
              filename="X.509 Certificate"
              label="2. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          ) : (
            <>
              <TextField
                error={errors?.saml_entity_id}
                label="2. IdP Entity ID / Issuer"
                name="saml_entity_id"
                onChange={onInputChange}
                placeholder="https://aaf4346.my.idaptive.app/e921b128-0543-4f7b-9968-ab92ec522f2f"
                value={connection?.saml_entity_id}
              />

              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="3. X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </>
          )}
        </Form>
      </Card>
    </Article>
  );
};
