import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { DownloadInput, ReadOnlyInput } from 'components/input';
import { Article, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const AdfsConfigureRelyingPartyTrust: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 1: Configure a Relying Party Trust
    </Text>

    <Paragraph>Open the AD FS Management console.</Paragraph>

    <Img priority height={912} src="/sso/adfs/v1/adfs-1.png" width={1278} />

    <Paragraph>Click “Relying Party Trusts” on the left sidebar.</Paragraph>

    <Img priority height={912} src="/sso/adfs/v1/adfs-2.png" width={1278} />

    <Paragraph>
      Open the “AD FS Relying Party Trust Wizard” by clicking “Add Relying Party
      Trust...” on the right sidebar.
    </Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-3.png" width={1278} />

    <Paragraph>Select “Claims aware” and then “Start”.</Paragraph>

    <Paragraph>Download the provided Metadata file from WorkOS.</Paragraph>

    <DownloadInput
      filename="metadata.xml"
      label="Your SP Metadata File"
      value={connection?.saml_sp_metadata_url}
    />

    <Paragraph>
      Select “Import data about the relying party from a file,” then select the
      SP Metadata file you downloaded, then click “Next”.
    </Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-4.png" width={1278} />

    <Paragraph>Select “Permit everyone” and then “Next”.</Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-5.png" width={1278} />

    <Confirm
      buttonText="Continue to Next Step"
      label="I’ve selected “Permit everyone” and clicked on “Next”."
      onClick={onNextStep}
    />
  </Article>
);

export const AdfsAccessPolicy: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 2: Choose Access Policy
    </Text>

    <Paragraph>
      Click the “Endpoints” tab and confirm that the “SAML Assertion Consumer
      Endpoints” matches the URL below and click “Next”.
    </Paragraph>

    <Img priority height={912} src="/sso/adfs/v1/adfs-6.png" width={1278} />

    <ReadOnlyInput
      label="Confirm this URL matches the SAML Assertion Consumer Endpoint"
      value={connection?.saml_acs_url}
    />

    <Paragraph>
      Select “Configure claims issuance policy for this application” and
      “Close”.
    </Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-7.png" width={1278} />

    <Confirm
      label="I’ve finished and closed the Relying Party Trust Wizard."
      onClick={onNextStep}
    />
  </Article>
);

export const AdfsClaimsPolicy: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 3: Configure Claims Issuance Policy
    </Text>

    <Paragraph>
      Click “Add Rule” in the “Edit Claims Issuance Policy” window.
    </Paragraph>

    <Img priority height={912} src="/sso/adfs/v1/adfs-8.png" width={1278} />

    <Paragraph>
      Select “Send LDAP Attributes as Claims” and then “Next”.
    </Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-9.png" width={1278} />

    <Paragraph>Submit “Attributes” as “Claim rule name:”</Paragraph>

    <Paragraph>Select “Active Directory” as “Attribute Store”</Paragraph>

    <Paragraph>Fill in the following Attribute mappings:</Paragraph>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Paragraph>Click “Finish”.</Paragraph>

    <Img height={582} src="/sso/adfs/v1/adfs-10.png" width={716} />

    <Confirm
      label="I’ve finished and closed the claims issuance policy."
      onClick={onNextStep}
    />
  </Article>
);

export const AdfsTokenSigningCertificate: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, errors, isLoading, onFileInput, onNextStep }) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 4: Upload Token Signing Certificate
    </Text>

    <Paragraph>
      {`Click on Service > Certificates and select the “Token-signing”
          certificate and “View Certificate” in the right side bar.`}
    </Paragraph>

    <Img priority height={912} src="/sso/adfs/v1/adfs-11.png" width={1278} />

    <Paragraph>
      Click the “Details” tab and then click “Copy to File” in the Certificate
      window.
    </Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-12.png" width={1278} />

    <Paragraph>
      Select “No, do not export the private key” then “Next”.
    </Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-13.png" width={1278} />

    <Paragraph>Select “Base-64 encoded X.509 (.CER)” then “Next”.</Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-14.png" width={1278} />

    <Paragraph>
      Specify a file name and finish exporting the public certificate.
    </Paragraph>

    <Img height={912} src="/sso/adfs/v1/adfs-15.png" width={1278} />

    <Card>
      <Form
        disabled={!connection?.saml_x509_certs}
        isLoading={isLoading}
        isUpdate={!!errors?.saml_x509_certs}
        onSubmit={onNextStep}
      >
        <FileField
          error={errors?.saml_x509_certs}
          filename="Token Signing Certificate"
          label="Token Signing Certificate"
          name="saml_x509_certs"
          onUpload={onFileInput}
          value={connection?.saml_x509_certs?.[0]}
        />
      </Form>
    </Card>
  </Article>
);

export const AdfsProvideEndpoint: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  errors,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 5: Provide SAML 2.0 Endpoint
    </Text>

    <Paragraph>
      {`Click on Service > Endpoints and find your “SAML 2.0/WS Federation”
          Endpoint.`}
    </Paragraph>

    <Img priority height={912} src="/sso/adfs/v1/adfs-16.png" width={1278} />

    <Paragraph>
      Provide your SAML 2.0 Endpoint, it will be located at the endpoint found;
      ex: https://adfs.foocorp.com/adfs/ls
    </Paragraph>

    <Card>
      <Form
        disabled={!connection?.saml_idp_url}
        error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
        isInline={true}
        isLoading={isLoading}
        isUpdate={!!errors?.saml_idp_url}
        onSubmit={onNextStep}
      >
        <TextField
          autoFocus={true}
          label="SAML 2.0 Endpoint"
          name="saml_idp_url"
          onChange={onInputChange}
          placeholder="https://adfs.foocorp.com/adfs/123"
          value={connection?.saml_idp_url}
        />
      </Form>
    </Card>
  </Article>
);
