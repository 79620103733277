import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, Paragraph } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';

export const RipplingCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 1: Create Rippling SAML Application
    </Text>

    <Img
      height={900}
      priority={true}
      src="/sso/rippling/v1/rippling-1.png"
      width={1200}
    />
    <Paragraph>
      Log in to Rippling as an Adminstrator and select "IT Management" then
      "Custom App" from the left-side navigation bar.
    </Paragraph>

    <Img
      height={600}
      priority={true}
      src="/sso/rippling/v1/rippling-2.png"
      width={1000}
    />

    <Paragraph>
      Select "Create New App" to begin creating a new SAML application.
    </Paragraph>

    <Img
      priority
      height={1000}
      src="/sso/rippling/v1/rippling-3.png"
      width={800}
    />

    <Paragraph>
      Give the app a descriptive name. You can select a category if relevant,
      but this field is not required. You will however, need to upload a logo
      file. Make sure to check the box for "Supports SAML", then click "Create
      App".
    </Paragraph>

    <Img
      priority
      height={500}
      src="/sso/rippling/v1/rippling-4.png"
      width={800}
    />

    <Paragraph>
      Select the option confirming that you are the Application Admin. Rippling
      will display a new page with information we will use in the next step.
    </Paragraph>

    <Confirm
      label="I've configured the application and confirmed that I am the Application Admin."
      onClick={onNextStep}
    />
  </Article>
);

export const RipplingInputIdpDetails: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => {
  const isUseGeneratedConnectionEntityIdEnabled = useFeature(
    'useGeneratedConnectionEntityId',
  );

  return (
    <Article>
      <Text as="h1" size="xlarge" weight="medium">
        Step 2: Input the Rippling IdP Details
      </Text>

      {isUseGeneratedConnectionEntityIdEnabled ? (
        <Paragraph>
          Rippling will now provide the SSO set up instructions. Step 1 will
          include the SSO URL and X509 Certificate.
        </Paragraph>
      ) : (
        <Paragraph>
          Rippling will now provide the SSO set up instructions. Step 1 will
          include the SSO URL, IdP Entity ID, and X509 Certificate.
        </Paragraph>
      )}

      <Img
        height={500}
        priority={true}
        src="/sso/rippling/v1/rippling-5.png"
        width={550}
      />

      <Paragraph>
        Rippling provides the X509 certificate as a text blob. This will need to
        be copy and pasted into a text file and saved with a file extension type
        shown in the admin portal and then uploaded.
      </Paragraph>

      <Card>
        <Form
          disabled={
            !connection?.saml_idp_url ||
            !connection?.saml_x509_certs ||
            !connection?.saml_entity_id ||
            validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
          }
          isLoading={isLoading}
          isUpdate={
            !!(
              errors?.saml_idp_url ||
              errors?.saml_entity_id ||
              errors?.saml_x509_certs
            )
          }
          onSubmit={onNextStep}
        >
          <TextField
            autoFocus={true}
            error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
            label="1. Single Sign On URL"
            name="saml_idp_url"
            onChange={onInputChange}
            placeholder="https://www.rippling.com/api/platform/sso/sp-initiated/12345678d0324dc3669b2dd5"
            value={connection?.saml_idp_url}
          />

          {isUseGeneratedConnectionEntityIdEnabled ? (
            <FileField
              error={errors?.saml_x509_certs}
              filename="X.509 Certificate"
              label="2. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          ) : (
            <>
              <TextField
                error={errors?.saml_entity_id}
                label="2. IdP Entity ID / Issuer"
                name="saml_entity_id"
                onChange={onInputChange}
                placeholder="https://www.rippling.com/12345678dc3669b2dd5/idp.xml"
                value={connection?.saml_entity_id}
              />

              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="3. X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </>
          )}
        </Form>
      </Card>
    </Article>
  );
};

export const RipplingUploadAcsUrl: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 3: Upload ACS URL and SP Entity ID to Rippling
    </Text>

    <Paragraph>
      Scrolling down on the SSO Setup Instructions, Rippling will request the
      ACS URL and Service Provider Entity ID.
    </Paragraph>

    <CopyInput
      label="Copy this Single Sign On URL"
      value={connection?.saml_acs_url}
    />

    <Paragraph>
      Input the Single Sign On URL above as both the ACS URL and SP Entity ID.
    </Paragraph>

    <Img
      height={500}
      priority={true}
      src="/sso/rippling/v1/rippling-6.png"
      width={500}
    />

    <Paragraph>Click on the "Move To Next Step" button.</Paragraph>

    <Confirm
      label="I’ve uploaded the SSO URL as the ACS URL and SP Entity ID into Rippling."
      onClick={onNextStep}
    />
  </Article>
);

export const RipplingConfigureAttributeMapping: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Text as="h1" size="xlarge" weight="medium">
      Step 4: Configure Attribute Mapping
    </Text>

    <Paragraph>
      Rippling will now have configuration settings for your preferences
      regarding the application like who should have access and when. Configure
      these settings as needed until you reach the final page letting you know
      the installation has been complete.
    </Paragraph>

    <Img
      height={400}
      priority={true}
      src="/sso/rippling/v1/rippling-7.png"
      width={700}
    />
    <Paragraph>
      Click "Visit the app". The application settings will be presented, here we
      will configure the SAML attribute mapping.
    </Paragraph>

    <Img
      height={400}
      priority={true}
      src="/sso/rippling/v1/rippling-8.png"
      width={700}
    />

    <Paragraph>
      Select the "Settings Tab" then on the left navigation select "SAML
      Attributes" and select the "SAML Response" tab and use the "edit" button
      to add the following key-value pairs. Then, click "Save".
    </Paragraph>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've completed the application configuration and SAML attribute mapping."
      onClick={onNextStep}
    />
  </Article>
);
